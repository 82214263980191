// External packages
import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Paragraph } from "theme-ui"

// Types
import { ContentfulHero } from "types"

// Utilities
import { getContentfulUrl } from "./utils"

// Components
import { Link } from "../Link"

export const Hero: React.FC<{
  data: ContentfulHero
}> = ({ data }) => (
  <Link
    to={getContentfulUrl({ linkUrl: data?.urlLink, link: data?.link })}
    sx={{
      display: "block",
      marginInline: data?.sideMargins,
      marginBlockStart: data?.topMargin,
      marginBlockEnd: data?.bottomMargin,
      img: {
        objectFit: "cover",
        objectPosition: "center",
      },
    }}
  >
    {Boolean(data?.desktopImage) ? (
      data?.desktopImage?.file?.contentType?.startsWith("video") ? (
        <Box
          sx={{
            display: ["none", "block"],
            height: "100%",
            video: {
              display: "block",
              width: "100%",
              height: "100%",
              aspectRatio: "16 / 9",
              objectFit: "cover",
              objectPosition: "center",
            },
          }}
        >
          <video
            src={data?.desktopImage?.file?.url}
            autoPlay
            muted={true}
            loop
            playsInline
          >
            <p>Your browser doesn't support HTML video.</p>
          </video>
        </Box>
      ) : (
        <Box
          sx={{
            display: ["none", "block"],
            img: {
              aspectRatio: "16 / 9",
            },
          }}
        >
          <GatsbyImage
            image={data?.desktopImage?.gatsbyImageData}
            alt={data?.title}
          />
        </Box>
      )
    ) : null}
    {Boolean(data?.mobileImage) ? (
      data?.mobileImage?.file?.contentType?.startsWith("video") ? (
        <Box
          sx={{
            display: ["block", "none"],
            height: "100%",
            video: {
              display: "block",
              width: "100%",
              height: "100%",
              aspectRatio:
                data?.mobileImageRatio === "9:16" ? "9 / 16" : "4 / 5",
              objectFit: "cover",
              objectPosition: "center",
            },
          }}
        >
          <video
            src={data?.mobileImage?.file?.url}
            autoPlay
            muted={true}
            loop
            playsInline
          >
            <p>Your browser doesn't support HTML video.</p>
          </video>
        </Box>
      ) : (
        <Box
          sx={{
            display: ["block", "none"],
            img: {
              aspectRatio:
                data?.mobileImageRatio === "9:16" ? "9 / 16" : "4 / 5",
            },
          }}
        >
          <GatsbyImage
            image={data?.mobileImage?.gatsbyImageData}
            alt={data?.title}
          />
        </Box>
      )
    ) : null}
    {Boolean(data?.title) ? (
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          width: "100%",
          backgroundColor: "grayscale.100",
          paddingBlockStart: 2,
          paddingBlockEnd: [6, 8],
          paddingInline: data?.sideMargins === "0px" ? 4 : null,
        }}
      >
        <Paragraph
          sx={{
            fontSize: "sm",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {data?.title}
        </Paragraph>
      </Box>
    ) : null}
  </Link>
)
