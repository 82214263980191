// External packages
import * as React from "react"
import { Flex } from "theme-ui"

// Types
import { ContentfulPage } from "types"

// Utilities
import {
  isContentfulGrid,
  isContentfulButton,
  isContentfulHero,
  isContentfulImageGallery,
  isContentfulText,
} from "./utils"

// Components
import { Hero } from "./Hero"
import { Grid } from "./Grid"
import { Button } from "./Button"
import { Text } from "./Text"
import { ImageGallery } from "./ImageGallery"

export const Page: React.FC<{
  data: ContentfulPage
}> = ({ data }) => (
  <Flex sx={{ flexDirection: "column", rowGap: data?.rowGap }}>
    {data.contentModules.map((item) => {
      if (isContentfulGrid(item)) {
        return <Grid key={item.id} data={item} />
      }

      if (isContentfulHero(item)) {
        return <Hero key={item.id} data={item} />
      }

      if (isContentfulImageGallery(item)) {
        return <ImageGallery key={item.id} data={item} />
      }

      if (isContentfulButton(item)) {
        return <Button key={item.id} data={item} />
      }

      if (isContentfulText(item)) {
        return <Text key={item.id} data={item} />
      }

      return null
    })}
  </Flex>
)
